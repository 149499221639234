import React, {useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styles from './Hero.module.css';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import axios from 'axios';


const Hero = () => {
    const navigate = useNavigate()
    const [images, setImages] = useState([])

    useEffect(() => {
        const getAllBanners = async () => {
            await axios.get("https://api.mufcredbrigade.com/api/all-banners").then((res) => {
                setImages(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
        getAllBanners()
    }, [])
    
    return (
        <div className={styles.hero}>
            <div className={styles.left}>
                <Swiper
                    spaceBetween={10}
                    pagination={{ clickable: true }}
                    loop={true}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: true,
                    }}
                    modules={[Autoplay]}
                    className={styles.swiper}
                >
                    {
                        images && images.map((image) => {
                            return (
                                <SwiperSlide key={image._id} ><img src={`https://api.mufcredbrigade.com/${image.imageUrl}`} alt={`image${image._id}`} className={styles.swiperProfile} /></SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            <div className={styles.right}>
                <h2 className={styles.brandTitle}>Members Login</h2>
                <form className={styles.form}>
                    <input type="text" placeholder="Membership #" className={styles.inputField} />
                    <input type="password" placeholder="Password" className={styles.inputField} />
                    <div className={styles.actions}>
                        <a href="#" className={styles.forgotLink}>Forgotten your password?</a>
                        <button type="submit" className={styles.loginButton}>Login</button>
                    </div>
                </form>
                <div className={styles.joinSection}>
                    <p className={styles.joinText}>Want to become a member?</p>
                    <button onClick={()=> {navigate("/join-us")}} className={styles.joinButton}>Join</button>
                </div>
            </div>
        </div>
    );
};

export default Hero;